import { ComponentProps } from "react";
import Icon from "../icons";
import { TouchableOpacity } from "./styled";
import clsx from "clsx";

type ClickableIconProps = ComponentProps<typeof Icon> & {
  textClassName?: string;
};

export default function ClickableIcon(props: ClickableIconProps) {
  const { icon, size, onPress, textClassName, ...iconProps } = props;
  return (
    <TouchableOpacity
      onPress={onPress}
      className={clsx(textClassName, "rounded-md bg-gray-100 p-1")}
    >
      <Icon icon={icon} size={size} {...iconProps} />
    </TouchableOpacity>
  );
}
